@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;700&display=swap');

* {
  box-sizing: border-box;
}
*::before, *::after {
  box-sizing: border-box;
}

body {
  font-family: "Rubik", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  min-height: 100vh;
  background: #fff;
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
}
button.miss-you-button {
  font-weight: 600;
  color: #382b22;
  text-transform: uppercase;
  padding: 1.25em 2em;
  background: #fff0f0;
  border: 2px solid #b18597;
  border-radius: 0.75em;
  transform-style: preserve-3d;
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
}
button.miss-you-button::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f9c4d2;
  border-radius: inherit;
  box-shadow: 0 0 0 2px #b18597, 0 0.625em 0 0 #ffe3e2;
  transform: translate3d(0, 0.75em, -1em);
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}
button.miss-you-button:hover {
  background: #ffe9e9;
  transform: translate(0, 0.25em);
}
button.miss-you-button:hover::before {
  box-shadow: 0 0 0 2px #b18597, 0 0.5em 0 0 #ffe3e2;
  transform: translate3d(0, 0.5em, -1em);
}
button.miss-you-button:active {
  background: #ffe9e9;
  transform: translate(0em, 0.75em);
}
button.miss-you-button:active::before {
  box-shadow: 0 0 0 2px #b18597, 0 0 #ffe3e2;
  transform: translate3d(0, 0, -1em);
}

button.miss-you-button:disabled::before {
  visibility: hidden;
}
button.miss-you-button:disabled {
  background-color: #e1e1e1;
  border-color: #bfbebe;
  color: #686767;
  pointer-events: none;
}

.banner {
  text-transform: uppercase;
  text-align: center;
  opacity: 0;
  padding: 0 100px;
  font-weight: 700;
  min-height: 80px;
}

.banner-footer {
  text-align: center;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fade-in-out {
  animation: fadeInOut ease 10s;
  -webkit-animation: fadeInOut ease 10s;
  -moz-animation: fadeInOut ease 10s;
  -o-animation: fadeInOut ease 10s;
  -ms-animation: fadeInOut ease 10s;
}


@keyframes fadeInOut{
  0%, 100% {
    opacity:0;
  }
  30%,50%,70% {
    opacity:.4;
  }
}

.fade-in {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}


@keyframes fadeIn{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.link, a {
  color: #6a00ff;
  padding: 1em;
  background: #fff0f0;
  border-radius: 0.75em;
  text-decoration: none;
}

button.coupon {
  background: none;
  border: 4px solid #ffc0cbab;
  border-radius: 8px;
  padding: 16px;
  color: #464646;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10vh;
  align-items: center;
}

#countdown {
  background: #ffc0cb3d;
  border-radius: 8px;
  padding: 16px;
  text-align: left;
}

.popover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  z-index: 999;
  width: 80%;
  height: 90%;
  border-radius: 8px;
  overflow: scroll;
  max-width: 420px;
  box-shadow: 0px 3px 23px 4px #0000001a;
  padding: 20px;
}

.popover-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popover-title {
  font-size: xx-large;
}